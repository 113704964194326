import React from "react"
import LayoutComponent from "../components/Layout.js"
import Knap from "../components/Knap.js"

const IndexPage = () => (
    <LayoutComponent>
        <h1>Om Easymed</h1>
        <p>Hos Easymed har vi ett tätt och tryggt samarbete med både våra kunder och leverantörer i branschen. Sedan vi startade 1996, har vi varit en fast och stabil leverantör av röntgentillbehör, utrustning och förbrukningsartiklar i hela Skandinavien.</p>
        <p>Vårt sortiment utvidgar sig löpande i takt med de senaste radiologiska innovationer. om du söker något specifikt, så uppmanar vi dig till att ta kontakt til oss direkt.</p><br />
        <section className="easymed__grid__wrapper">
            <div className="easymed__grid__single__contact">
                <p className="medarbejder__navn">Benjamin Broen</p>
                <p className="medarbejder__titel">Direktör</p>
                <p className="medarbejder__tlf"><a href="tel:004527205354">+45 27205354</a></p>
            </div>
            <div className="easymed__grid__single__contact">
                <p className="medarbejder__navn">Lars Broen</p>
                <p className="medarbejder__titel">Säljrepresentant</p>
                <p className="medarbejder__tlf"><a href="tel:004526731454">+45 26731454</a></p>
            </div>
        </section>
        <Knap />
    </LayoutComponent>
)

export default IndexPage